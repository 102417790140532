/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ReflexCompanyUnitData } from '../models/reflex-company-unit-data';
import { ReflexEventData } from '../models/reflex-event-data';
import { ReflexEventUserData } from '../models/reflex-event-user-data';
import { ReflexUserData } from '../models/reflex-user-data';

@Injectable({
  providedIn: 'root',
})
export class ReflexExportService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation reflexExportExportReflexCompanyUnits
   */
  static readonly ReflexExportExportReflexCompanyUnitsPath = '/api/ReflexExport/ExportReflexCompanyUnits';

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reflexExportExportReflexCompanyUnits$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexCompanyUnits$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReflexCompanyUnitData>>> {

    const rb = new RequestBuilder(this.rootUrl, ReflexExportService.ReflexExportExportReflexCompanyUnitsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReflexCompanyUnitData>>;
      })
    );
  }

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reflexExportExportReflexCompanyUnits$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexCompanyUnits$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<ReflexCompanyUnitData>> {

    return this.reflexExportExportReflexCompanyUnits$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReflexCompanyUnitData>>) => r.body as Array<ReflexCompanyUnitData>)
    );
  }

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reflexExportExportReflexCompanyUnits()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexCompanyUnits$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReflexCompanyUnitData>>> {

    const rb = new RequestBuilder(this.rootUrl, ReflexExportService.ReflexExportExportReflexCompanyUnitsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReflexCompanyUnitData>>;
      })
    );
  }

  /**
   * Cég/egységek lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reflexExportExportReflexCompanyUnits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexCompanyUnits(params?: {
  },
  context?: HttpContext

): Observable<Array<ReflexCompanyUnitData>> {

    return this.reflexExportExportReflexCompanyUnits$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReflexCompanyUnitData>>) => r.body as Array<ReflexCompanyUnitData>)
    );
  }

  /**
   * Path part for operation reflexExportExportReflexUsers
   */
  static readonly ReflexExportExportReflexUsersPath = '/api/ReflexExport/ExportReflexUsers';

  /**
   * Felhasználók listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reflexExportExportReflexUsers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexUsers$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReflexUserData>>> {

    const rb = new RequestBuilder(this.rootUrl, ReflexExportService.ReflexExportExportReflexUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReflexUserData>>;
      })
    );
  }

  /**
   * Felhasználók listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reflexExportExportReflexUsers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexUsers$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<ReflexUserData>> {

    return this.reflexExportExportReflexUsers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReflexUserData>>) => r.body as Array<ReflexUserData>)
    );
  }

  /**
   * Felhasználók listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reflexExportExportReflexUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexUsers$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReflexUserData>>> {

    const rb = new RequestBuilder(this.rootUrl, ReflexExportService.ReflexExportExportReflexUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReflexUserData>>;
      })
    );
  }

  /**
   * Felhasználók listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reflexExportExportReflexUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexUsers(params?: {
  },
  context?: HttpContext

): Observable<Array<ReflexUserData>> {

    return this.reflexExportExportReflexUsers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReflexUserData>>) => r.body as Array<ReflexUserData>)
    );
  }

  /**
   * Path part for operation reflexExportExportReflexEvents
   */
  static readonly ReflexExportExportReflexEventsPath = '/api/ReflexExport/ExportReflexEvents';

  /**
   * Események listázása reflex számára.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reflexExportExportReflexEvents$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexEvents$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReflexEventData>>> {

    const rb = new RequestBuilder(this.rootUrl, ReflexExportService.ReflexExportExportReflexEventsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReflexEventData>>;
      })
    );
  }

  /**
   * Események listázása reflex számára.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reflexExportExportReflexEvents$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexEvents$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<ReflexEventData>> {

    return this.reflexExportExportReflexEvents$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReflexEventData>>) => r.body as Array<ReflexEventData>)
    );
  }

  /**
   * Események listázása reflex számára.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reflexExportExportReflexEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexEvents$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReflexEventData>>> {

    const rb = new RequestBuilder(this.rootUrl, ReflexExportService.ReflexExportExportReflexEventsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReflexEventData>>;
      })
    );
  }

  /**
   * Események listázása reflex számára.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reflexExportExportReflexEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexEvents(params?: {
  },
  context?: HttpContext

): Observable<Array<ReflexEventData>> {

    return this.reflexExportExportReflexEvents$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReflexEventData>>) => r.body as Array<ReflexEventData>)
    );
  }

  /**
   * Path part for operation reflexExportExportReflexEventUsers
   */
  static readonly ReflexExportExportReflexEventUsersPath = '/api/ReflexExport/ExportReflexEventUsers';

  /**
   * Esemény részvevői reflex számára.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reflexExportExportReflexEventUsers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexEventUsers$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReflexEventUserData>>> {

    const rb = new RequestBuilder(this.rootUrl, ReflexExportService.ReflexExportExportReflexEventUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReflexEventUserData>>;
      })
    );
  }

  /**
   * Esemény részvevői reflex számára.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reflexExportExportReflexEventUsers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexEventUsers$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<ReflexEventUserData>> {

    return this.reflexExportExportReflexEventUsers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReflexEventUserData>>) => r.body as Array<ReflexEventUserData>)
    );
  }

  /**
   * Esemény részvevői reflex számára.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reflexExportExportReflexEventUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexEventUsers$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReflexEventUserData>>> {

    const rb = new RequestBuilder(this.rootUrl, ReflexExportService.ReflexExportExportReflexEventUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReflexEventUserData>>;
      })
    );
  }

  /**
   * Esemény részvevői reflex számára.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reflexExportExportReflexEventUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reflexExportExportReflexEventUsers(params?: {
  },
  context?: HttpContext

): Observable<Array<ReflexEventUserData>> {

    return this.reflexExportExportReflexEventUsers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReflexEventUserData>>) => r.body as Array<ReflexEventUserData>)
    );
  }

}
