<!--<h2 mat-dialog-title>{{'UI.Label.SetPassword.Title|Jelszó módosítás' | translate}}</h2>-->
<div class="password-change-wrapper">
  <div [formGroup]="passwordForm" class="password-fields">
    <mat-form-field [appearance]="appearance">
      <mat-label>{{ 'UI.Label.SetPassword.NewPassword|Új jelszó' | translate }}</mat-label>
      <input autofocus matInput [type]="hide ? 'password' : 'text'" name="password1" formControlName="password1" (keydown.enter)="enterEvent()" #firstPasswordInput />
      <mat-icon matIconSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>

    <mat-form-field [appearance]="appearance">
      <mat-label>{{ 'UI.Label.SetPassword.NewPasswordConfirm|Új jelszó még egyszer' | translate }}</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" name="password2" formControlName="password2" (keydown.enter)="enterEvent()" />
      <mat-icon matIconSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
  </div>
  <div class="errors">
    <h4 *ngIf="requirementstitle">{{ 'UI.Label.SetPassword.RestrictionsTitle|Új jelszó kötelező jellemzői:' | translate }}</h4>
    <div class="requirement" [class.ok]="requirementIsOK('minlength')">
      <mat-icon>{{requirementIcon('minlength')}}</mat-icon>
      <span *ngIf="isDarkMode && requirementIcon('minlength') === 'check_circle'" class="icon-bg"></span>
      <span>{{ 'UI.Label.Common.Error.MinLength|Legalább {{value}\} karakter' | translate:{ value: 6 } }}</span>
    </div>
    <div class="requirement" [class.ok]="requirementIsOK('lowercaseLetter')">
      <mat-icon>{{requirementIcon('lowercaseLetter')}}</mat-icon>
      <span *ngIf="isDarkMode && requirementIcon('lowercaseLetter') === 'check_circle'" class="icon-bg"></span>
      <span>{{ 'UI.Label.Common.Error.LowerCaseLetter|Kisbetűt tartalmaz' | translate }}</span>
    </div>
    <div class="requirement" [class.ok]="requirementIsOK('uppercaseLetter')">
      <mat-icon>{{requirementIcon('uppercaseLetter')}}</mat-icon>
      <span *ngIf="isDarkMode && requirementIcon('uppercaseLetter') === 'check_circle'" class="icon-bg"></span>
      <span>{{ 'UI.Label.Common.Error.UpperCaseLetter|Nagybetűt tartalmaz' | translate }}</span>
    </div>
    <div class="requirement" [class.ok]="requirementIsOK('containsNumber')">
      <mat-icon>{{requirementIcon('containsNumber')}}</mat-icon>
      <span *ngIf="isDarkMode && requirementIcon('containsNumber') === 'check_circle'" class="icon-bg"></span>
      <span>{{ 'UI.Label.Common.Error.ContainsNumber|Számot tartalmaz' | translate }}</span>
    </div>
    <div class="requirement" [class.ok]="requirementIsOK('confirmPassword', true)">
      <mat-icon>{{requirementIcon('confirmPassword', true)}}</mat-icon>
      <span *ngIf="isDarkMode && requirementIcon('confirmPassword', true) === 'check_circle'" class="icon-bg"></span>
      <span>{{'UI.Label.Error.ConfirmPasswordMustMatch|A két jelszó megegyezik!' | translate }}</span>
    </div>
  </div>
</div>
<!--<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">{{ 'UI.Label.Common.Cancel|Mégsem' | translate }}</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="saveIsDisabled()">{{ 'UI.Label.SetPassword.Confirm|Jelszó módosítása' | translate }}</button>
</mat-dialog-actions>-->
