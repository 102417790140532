import { EventEmitter, Injectable } from '@angular/core';
import { MessageModalComponent } from '../../shared/components/message-modal/message-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

export interface IModalState {
  show: boolean;
  text?: string;
  showProgress: boolean;
  progress: number;
}

export interface ILoaderState {
  show: boolean;
  text?: string;
}


export interface IModalMessage {
  type: 'error' | 'warning' | 'info' | 'none';
  title?: string;
  message: string;
  info?: string;
  buttons?: ('ok' | 'cancel' | 'yes' | 'no' | 'close' | 'delete')[];
  onClose?: (result: string) => void;
  buttonText?: string[];
  buttonColor?: string[];
  buttonBgColor?: string[];
  disableClose?: boolean;
  footerInfo?: string;
  backdropClass?: string;
  isMobile?: boolean;
  panelClasses?: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class UINotificationService {
  public suppressLoader = false;

  public modalState$: EventEmitter<IModalState> = new EventEmitter();
  public loaderState$: EventEmitter<ILoaderState> = new EventEmitter();
  private snackBarRef: MatSnackBarRef<any>;
  constructor(
    private modalService: MatDialog,
    private snackBar: MatSnackBar) { }

  private modalWaitCounter = 0;

  public showModalWait(text: string, progress: number | null = null) {
    this.modalState$.emit({
      show: true,
      text: text,
      showProgress: progress !== null,
      progress: progress !== null ? <number>progress : 0
    });
  }

  public hideModalWait() {
    this.modalState$.emit({
      show: false,
      text: '',
      showProgress: false,
      progress: 0
    });
  }

  public showLoader(text: string) {
    this.loaderState$.emit({
      show: true,
      text: text
    });
  }
  public hideLoader() {
    this.loaderState$.emit({
      show: false,
      text: ''
    });
  }

  public showModalMessage(params: IModalMessage) {
    return new Promise<string>(resolve => {
      const onClose = params.onClose ?
        (result: string) => {
          if (params.onClose !== undefined) {
            params.onClose(result);
          }
          resolve(result);
        } :
        resolve;

      let buttons = params.buttons;
      if (!params.buttons || !params.buttons.length) {
        buttons = ['close'];
      }

      this.modalService.open(MessageModalComponent, {
        data: {
          ...params, buttons, onClose
        },
        panelClass: this.getPanelClass(params),
        backdropClass: params.backdropClass,
        disableClose: params.disableClose
      });
    });
  }

  public hideAll() {
    this.modalService.closeAll();
  }

  public showSnackbar(message: string, duration?: number, action?: string, clicked?:any) {
    this.snackBarRef = this.snackBar.open(message, action, {
      duration: duration || 2 * 1000,
      horizontalPosition: 'start',
    });
    this.snackBarRef.onAction().subscribe(() => {
      if (clicked) {
        clicked();
      }
    });
  }

  private getPanelClass(params: IModalMessage): string | string[] {

    let wideModalText = params.isMobile === true ? 'wideModal-mobile' : 'wideModal';

    if (params.panelClasses && params.panelClasses.length > 0) {
      return Array.isArray(params.panelClasses) ? [...params.panelClasses, wideModalText] : [(params.panelClasses as string), wideModalText];
    }

    return wideModalText;
  }
}
