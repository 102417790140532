import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { GlobalErrorHandler } from '../../../core/providers/global-error-handler';
import { UserService } from '../../../../api/services/user.service';
import { ProfileService } from '../../../../api/services/profile.service';
import { lowercaseLettersValidator } from '../../validators/lowercase-letters-validator';
import { containsNumberValidator } from '../../validators/contains-number-validator';
import { confirmPasswordValidator } from '../../validators/confirm-password-validator';
import { uppercaseLettersValidator } from '../../validators/uppercase-letters-validator';
import { timer } from 'rxjs';
import { SessionProvider } from 'src/app/core/providers/session.provider';

export interface PasswordValidInterface {
  valid: boolean;
  password: string;
}

@Component({
  selector: 'user-setpassword',
  templateUrl: './user-setpassword.component.html',
  styleUrls: ['./user-setpassword.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserSetPasswordComponent implements OnInit {

  @Input() requirementstitle: boolean = false;
  @Input() appearance: 'fill' | 'outline' = 'fill';
  @Output() valid = new EventEmitter<PasswordValidInterface>();
  @Output() enterpressed = new EventEmitter<string>();

  @ViewChild('firstPasswordInput', { read: ElementRef }) firstPasswordInput: ElementRef<HTMLInputElement>;

  constructor(
    private errorHandler: GlobalErrorHandler,
    private userService: UserService,
    private profileService: ProfileService,
    private session: SessionProvider
    //private modalService: MatDialog,
    //public dialogRef: MatDialogRef<UserSetPasswordComponent>
  ) { }

  public passwordForm = new UntypedFormGroup({
    password1: new UntypedFormControl('', [Validators.required, Validators.minLength(6), lowercaseLettersValidator(), uppercaseLettersValidator(), containsNumberValidator()]),
    password2: new UntypedFormControl('', [Validators.required]),
  }, {
    validators: confirmPasswordValidator
  });

  hide: boolean = true;

  public cancel() {
    //this.closed.emit('cancel');
  }
  requirementIcon(errorName: string, formError: boolean = false): string {
    return this.requirementIsOK(errorName, formError) ? 'check_circle' : 'chevron_right';
  }
  requirementIsOK(errorName: string, formError: boolean = false): boolean {
    if ((this.passwordForm.touched || this.passwordForm.dirty) && this.passwordForm.controls['password1'].value !== '') {
      if (!formError) {
        return ((this.passwordForm.controls['password1'].errors === undefined || this.passwordForm.controls['password1'].errors === null)) ||
            !this.passwordForm.controls['password1'].errors[errorName]
          ? true
          : false;
      } else {
        return this.passwordForm.errors === null || (this.passwordForm.errors !== undefined)
          && this.passwordForm.controls['password1'].value !== ''
          && (!this.passwordForm.errors[errorName])
          ? true
          : false;
      }
    } else return false;
  }
  saveIsDisabled() {
    return !this.passwordForm.valid;
  }
  get isDarkMode() {
    return this.session.isDarkMode();
  }
  public focus() {
    timer(500).subscribe(x => {
      this.firstPasswordInput.nativeElement.focus();
    });
  }
  public async save() {
    let $error: any;

    const operation$ =
        this.profileService.changePassword({
          body: {
            newPassword: this.passwordForm.controls['password1'].value
          }
        });

    try {
      await operation$.toPromise();
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 403) {
        return;
      }

      $error = error;
    }
    //this.closed.emit(!$error ? 'ok' : 'cancel');

    if ($error) {
      await this.errorHandler.handleExpectedError($error);
    }
  }

  enterEvent() {
    this.enterpressed.emit(this.passwordForm.controls['password1'].value);
  }

  ngOnInit(): void {
    this.passwordForm.statusChanges.subscribe(state => {
      if (state === 'VALID') {
        this.valid.emit({
          password: this.passwordForm.controls['password1'].value,
          valid: true
        });
      } else {
        this.valid.emit({
          password: this.passwordForm.controls['password1'].value,
          valid: false
        });
      }
    })
  }
}
